"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataUriFilter = exports.ensureDateUri = void 0;
const js_base64_1 = require("js-base64");
const FileType = require('file-type');
function ensureDateUri(value) {
    return new Promise((resolve, reject) => {
        if (/^data\:/i.test(value)) {
            try {
                const b = value.split(/base64\,/i, 2)[1];
                if ((0, js_base64_1.isValid)(b))
                    resolve(value);
                else
                    reject(undefined);
            }
            catch (e) {
                reject(undefined);
            }
        }
        else if (value && value.length > 0) {
            if ((0, js_base64_1.isValid)(value)) {
                const b64buffer = Buffer.from(value, "base64");
                FileType.fromBuffer(b64buffer).then((result) => {
                    if (result)
                        resolve(`data:${result.mime};base64,${value}`);
                    else
                        reject('__unknown_file_type__');
                }, reject);
            }
            else {
                reject('__invalid_base64__');
            }
        }
        else {
            reject(undefined);
        }
    });
}
exports.ensureDateUri = ensureDateUri;
function DataUriFilter() {
    var hash = new Map();
    const dataUri = function (value, $scope) {
        if (hash.get(value)) {
            return hash.get(value).result;
        }
        else {
            const promise = ensureDateUri(value);
            promise.then((result) => {
                hash.set(value, { result: result });
                $scope.$applyAsync();
            }, (err) => {
                hash.set(value, { result: undefined });
                console.error(err);
                // if (err)
                //     alert(err)
            });
            return undefined;
        }
    };
    dataUri.$stateful = true;
    return dataUri;
}
exports.DataUriFilter = DataUriFilter;
