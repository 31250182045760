"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ngDropFile = void 0;
function ngDropFile($filter) {
    const fileType = 'image';
    const multiple = false;
    const maxSize = 5;
    return {
        restrict: 'A',
        require: 'ngModel',
        //@ts-ignore
        link: ($scope, $elem, $attrs, modelCtrl) => {
            const fileReaderMethod = $attrs.ngDropFile || 'readAsDataURL';
            $elem.on('dragover dragenter dragleave', (e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
            $elem.on('drop', (e) => {
                e.stopPropagation();
                e.preventDefault();
                if (e.dataTransfer) {
                    let filesCount = 0;
                    let loadList = [];
                    while (filesCount < e.dataTransfer.files.length) {
                        const file = e.dataTransfer.files.item(filesCount);
                        if (!new RegExp(`^${fileType}\/.*`).test(file.type)) {
                            $filter('translate')('__unknown_file_type__');
                            break;
                        }
                        else {
                            if (file.size > (maxSize * 1024 * 1024)) {
                                $filter('translate')('__max_file_size__', { maxFileSize: maxSize });
                                break;
                            }
                            else {
                                let r = new FileReader();
                                let loadPromise;
                                loadPromise = new Promise((resolve, reject) => {
                                    r.onload = (f) => {
                                        resolve(f.target.result);
                                        return f;
                                    };
                                    r.onerror = reject;
                                    r.onabort = reject;
                                });
                                r[fileReaderMethod](file);
                                loadList.push(loadPromise);
                            }
                        }
                        if (multiple)
                            filesCount++;
                        else
                            break;
                    }
                    Promise.all(loadList).then((values) => {
                        modelCtrl.$setViewValue(values.join('\n'));
                    }, (err) => {
                        if (err)
                            alert(err);
                    });
                }
            });
        }
    };
}
exports.ngDropFile = ngDropFile;
ngDropFile.$inject = ['$filter'];
