"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const action_1 = require("@src/app/services/action");
const js_base64_1 = require("js-base64");
const filters_1 = require("@games/_base64/src/filters");
const directives_1 = require("@games/_base64/src/directives");
const utils_1 = require("@src/shared/utils");
const iconv = require('iconv-lite');
function* generateChunks(array, size) {
    let start = 0;
    while (start < array.length) {
        yield array.slice(start, start + size);
        start += size;
    }
}
class Base64Ctrl {
    constructor($scope, $filter, $sce, $q, ConfigService, actionService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.$sce = $sce;
        this.$q = $q;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.inputEncodings = [
            {
                key: 'utf8',
                value: 'UTF-8',
                popular: true,
            }, {
                key: 'ascii',
                value: 'ASCII',
                popular: true,
            }, {
                key: 'cp1251',
                value: 'Windows-1251',
                popular: true,
            }, {
                key: 'cp1252',
                value: 'Windows-1252',
                popular: true,
            }, {
                key: 'cp866',
                value: 'CP866',
                popular: false,
            },
        ];
        this.tabs = [
            {
                key: 'text',
                value: this.$filter('translate')('Encode/decode')
            },
            {
                key: 'base64_to_image',
                value: this.$filter('translate')('Base64 to image')
            },
            {
                key: 'image_to_base64',
                value: this.$filter('translate')('Image to Base64')
            },
        ];
        this.tab = 'text';
        this.base64ToImageLoaded = false;
        this.encoding = 'utf8';
        this.charactersValue = 0;
        this.charactersOutput = 0;
        this.value = '';
    }
    $onInit() {
        this.$scope.$watch('$ctrl.value', (value) => {
            if (value == '') {
                this.charactersValue = 0;
                this.charactersOutput = 0;
                this.output = undefined;
            }
            else if (value != undefined) {
                this.charactersValue = value.replaceAll('\n', '').length;
            }
        });
        this.$scope.$watch('$ctrl.dropFile', (value) => {
            if (value) {
                this.value = value.split('base64,')[1];
                this.decodeImage();
            }
        });
        (0, rxjs_1.combineLatest)([
            this.ConfigService.settings$.pipe((0, operators_1.pluck)('per_line')),
            this.ConfigService.settings$.pipe((0, operators_1.pluck)('use_chunks')),
        ]).subscribe(() => {
            this.generate(this.lastType);
        });
    }
    $postLink() {
        const imagePreview = document.getElementById('image_preview');
        const self = this;
        const inMemoryImg = document.createElement('img');
        imagePreview.onload = () => {
            inMemoryImg.src = imagePreview.src;
            inMemoryImg.onload = () => {
                self.$scope.$apply(() => {
                    self.base64ToImageLoaded = true;
                    self.uploadImageSize = [inMemoryImg.width, inMemoryImg.height];
                });
            };
        };
        imagePreview.onerror = (err) => {
            self.$scope.$apply(() => {
                self.base64ToImageLoaded = false;
                self.uploadImageSize = undefined;
            });
        };
        // imagePreview.addEventListener('load', (event) => {
        //
        // })
    }
    generate(type = 'encode') {
        let output = '';
        this.lastType = type;
        if (this.value.length == 0) {
            this.output = undefined;
            this.charactersValue = 0;
            this.charactersOutput = 0;
        }
        else {
            if (this.ConfigService.cookieSettings.per_line) {
                output = this.value.split('\n')
                    .filter((item) => item.length > 0)
                    .map((item) => {
                    if (type == 'encode') {
                        let encodedItem = iconv.encode(item, this.encoding);
                        return (0, js_base64_1.fromUint8Array)(encodedItem);
                    }
                    else {
                        return (0, js_base64_1.decode)(item);
                    }
                })
                    .join('\n');
            }
            else {
                if (type == 'encode') {
                    const encodedValue = iconv.encode(this.value, this.encoding);
                    output = (0, js_base64_1.fromUint8Array)(encodedValue);
                }
                else {
                    output = (0, js_base64_1.decode)(this.value);
                }
            }
            if (this.ConfigService.cookieSettings.use_chunks) {
                this.output = [...generateChunks([...output], 76)]
                    .map((item) => item.join('')).join('\n');
            }
            else {
                this.output = output;
            }
            this.charactersOutput = this.output.replaceAll('\n', '').length;
        }
    }
    decodeImage() {
        (0, filters_1.ensureDateUri)(this.value).then((result) => {
            this.output = result;
            this.$scope.$digest();
        }, (err) => {
            alert(err);
            console.error(err);
        });
    }
    clear() {
        this.value = '';
        this.output = undefined;
        this.base64ToImageLoaded = false;
    }
    upload() {
        this.actionService.uploadFactory('text/*').then((value) => {
            this.$scope.$apply(() => {
                this.value = value;
            });
        }, (err) => {
            if (err)
                alert(err);
        });
    }
    uploadImage(scope) {
        this.actionService.uploadFactory('image/*').then((value) => {
            this.$scope.$apply(() => {
                this.value = value.split('base64,')[1];
                this.decodeImage();
            });
        }, (err) => {
            if (err)
                alert(err);
        });
    }
    download() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.tab == 'text') {
                this.actionService.downloadFactory('base64_zone', this.output);
            }
            else if (this.tab == 'base64_to_image') {
                try {
                    let dataUri = yield (0, filters_1.ensureDateUri)(this.value);
                    if (dataUri)
                        this.actionService.downloadFactory('base64_zone', (0, utils_1.dataURLtoBlob)(dataUri));
                    else {
                        throw '__bad_data_uri__';
                    }
                }
                catch (e) {
                    alert(this.$filter('translate')('__bad_data_uri__'));
                }
            }
        });
    }
    copy(id, value) {
        this.actionService.copyFactory(document.getElementById(id), value);
    }
    getImg(dataUri) {
        return dataUri ? this.$sce.trustAsHtml(`<img alt="" src="${dataUri}">`) : '';
    }
    getStyle(dataUri) {
        return dataUri ? this.$sce.trustAsHtml(`background-image: url(${dataUri});`) : '';
    }
    setTab(tab) {
        this.clear();
        this.tab = tab;
    }
    setEncoding(encoding) {
        this.encoding = encoding;
        this.generate(this.lastType);
    }
}
Base64Ctrl.$inject = ['$scope', '$filter', '$sce', '$q', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.filter('dataUri', filters_1.DataUriFilter);
appModule.directive('ngDropFile', directives_1.ngDropFile);
appModule.component('gameBase64', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: Base64Ctrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('base64/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            per_line: true
        });
    }]);
